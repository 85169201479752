<template>
  <div class="boss">
    <div class="box">
      <div class="content">
        <div class="heard">
          <img src="../assets/img/11.jpg" class="header" alt="" />
          <p class="name">小龙虾</p>
          <div class="qipao">
            <van-notice-bar text="我有一头小猪，我们一起养它吧！" />
          </div>
        </div>
        <div class="center">
          <img src="../assets/img/11.jpg" class="pig" alt="" />
          <div class="iAgree">
            <img
              src="../assets/img/ty.png"
              class="agree"
              @click="tongyi"
              alt=""
            />
            <p class="together">一起养小猪，养成大家一起分</p>
          </div>
        </div>
      </div>
    </div>
    <van-popup v-model="show">
      <div class="bossBox">
        <p class="verify">手机号绑定验证</p>
        <div>
          <van-cell-group>
            <van-field
              v-model="phone"
              clearable
              :border="false"
              type="number"
              maxlength="11"
              placeholder="请填写手机号"
              left-icon="http://static.yhaocang.com/img/pages/pc/phone.png"
            />
          </van-cell-group>
        </div>
        <div class="yanzheng">
          <van-cell-group class="cell">
            <van-field
              v-model="code"
              :border="false"
              placeholder="验证码"
              left-icon="http://static.yhaocang.com/img/pages/pc/ma.png"
            />
          </van-cell-group>
          <div v-show="codeShow" class="song" @click="getCode">获取验证码</div>
          <div v-show="!codeShow" class="song">
            {{ yzmTitle }}
          </div>
        </div>
        <div class="sure" @click="isTrue">确定</div>
      </div>
    </van-popup>
    <t-captcha-js src="https://ssl.captcha.qq.com/TCaptcha.js"></t-captcha-js>
  </div>
</template>

<script>
import { sendSms } from "../api/index";

export default {
  components: {
    "t-captcha-js": {
      render(createElement) {
        return createElement("script", {
          attrs: { type: "text/javascript", src: this.src },
        });
      },
      props: {
        src: { type: String, required: true },
      },
    },
  },
  data() {
    return {
      show: false,
      phone: "",
      code: "",
      yzmTitle: "",
      codeShow: true, //图片验证
    };
  },
  computed: {},
  watch: {},
  created() {},
  methods: {
    tongyi() {
      console.log("同意");
      this.show = !this.show;
    },
    isTrue() {
      console.log("确定");
      this.show = !this.show;
    },
    getCode() {
      if (!this.phone) {
        this.$toast("请输入手机号码");
        return;
      }
      let reg = /^((13|14|15|16|17|18|19)[0-9]{1}\d{8})$/;
      if (!reg.test(this.phone)) {
        this.$toast("请输入正确的手机号码");
        return;
      }
      this.showCodeImg();
    },
    showCodeImg() {
      // eslint-disable-next-line no-undef
      var captcha1 = new TencentCaptcha("2008794111", (coderes) => {
        console.log(coderes);
        const { randstr, ticket } = coderes;
        sendSms({ phone: this.phone, type1: 2, randstr, ticket }).then(
          (res) => {
            console.log(res);
            if (res.data.code == 200) {
              let time = 60;
              let timer = setInterval(() => {
                if (time == 0) {
                  clearInterval(timer);
                  this.codeShow = true;
                } else {
                  this.codeShow = false;
                  this.yzmTitle = time + "秒后重试";
                  time--;
                }
              }, 1000);
              this.$toast("验证码获取成功");
            } else {
              this.$toast(res.data.message);
            }
          }
        );
      });
      captcha1.show();
    },
  },
};
</script>

<style lang="scss" scoped>
p {
  margin: 0;
  padding: 0;
}
.boss {
  width: 100vw;
  height: 100vh;
  background-image: url("../assets/img/live.png");
  background-repeat: no-repeat;
  background-size: 100%;
  background-position: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  .box {
    width: 9rem;
    height: 17rem;
    background-image: url("../assets/img/agree.png");
    background-repeat: no-repeat;
    background-size: 100%;
    background-position: 100%;
    display: flex;
    justify-content: center;
    .content {
      width: 6.6rem;
      height: 11.2rem;
      margin-top: 4.1rem;
      display: flex;
      flex-direction: column;
      align-items: center;
      .heard {
        display: flex;
        flex-direction: column;
        align-items: center;
        .header {
          width: 1.6rem;
          height: 1.6rem;
          border-radius: 50%;
          overflow: hidden;
        }
        .name {
          font-size: 0.5rem;
          padding: 0.12rem 0.5rem;
          border-radius: 2rem;
          background: #d79f6d;
          border: 0.04rem solid #fff;
          margin-top: 0.1rem;
        }
        .qipao {
          width: 6rem;
          height: 0.9rem;
          background-image: url("../assets/img/pao.png");
          background-repeat: no-repeat;
          background-size: 100%;
          background-position: 100%;
          margin-top: 0.1rem;
          .van-notice-bar {
            width: 5.5rem;
            margin-top: 0.31rem;
            background: none;
            padding: 0 0.3rem;
            /deep/ .van-notice-bar__wrap {
              display: flex;
              justify-content: center;
              color: #945614;
            }
          }
        }
      }
      .center {
        width: 6rem;
        height: 4rem;
        margin-top: 0.5rem;
        .pig {
          width: 6rem;
          height: 4rem;
          object-fit: contain;
          border-radius: 0.2rem;
        }
        .iAgree {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          margin-top: 0.4rem;
          .agree {
            width: 5rem;
            height: auto;
          }
        }
        .together {
          font-size: 0.4rem;
          font-weight: 400;
          color: #945614;
          margin-top: 0.2rem;
        }
      }
    }
  }
}
.van-popup {
  border-radius: 0.15rem;
  overflow: hidden;
}
.bossBox {
  width: 8rem;
  height: 6.5rem;
  display: flex;
  flex-direction: column;
  align-items: center;

  .verify {
    font-size: 0.53rem;
    color: #333;
    font-weight: 400;
    padding: 0.8rem 0 0.25rem 0;
  }
  .yanzheng {
    width: 7rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .song {
      width: 2.76rem;
      height: 1.15rem;
      background: #43d227;
      border-radius: 0.1rem;
      margin-top: 0.2rem;
      font-size: 0.4rem;
      display: flex;
      justify-content: center;
      align-items: center;
      color: #fefefe;
      font-weight: 400;
    }
  }
  .sure {
    width: 4.6rem;
    height: 1.1rem;
    background: #43d227;
    font-size: 0.45rem;
    color: #fefefe;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 500;
    margin-top: 0.4rem;
    border-radius: 2rem;
  }
}
.van-cell-group {
  margin-top: 0.2rem;
  width: 7rem;
  .van-cell {
    height: 1.2rem;
    display: flex;
    align-items: center;
    background: #f2f2f2;
    border-radius: 0.15rem;
    overflow: hidden;
  }
  /deep/ .van-icon {
    font-size: 0.8rem;
    margin: 0 0.3rem 0 0.34rem;
  }

  /deep/ .van-field__body {
    font-size: 0.41rem;
  }
}
.cell {
  width: 4rem;
  /deep/ .van-icon {
    font-size: 0.65rem;
    margin: 0 0.3rem 0 0.41rem;
  }
}
</style>
